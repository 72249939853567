.layer-switcher {
  text-align: left;
  position: absolute;
  top: 3.5em;
  right: .5em;
}

.layer-switcher .panel {
  max-height: inherit;
  box-sizing: border-box;
  background-color: #fff;
  border: 4px solid #eee;
  border-radius: 4px;
  height: 100%;
  margin: 0;
  display: none;
  overflow-y: auto;
}

.layer-switcher button {
  float: right;
  z-index: 1;
  color: #000;
  background-color: #fff;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAMAAABEpIrGAAACE1BMVEX///8A//8AgICA//8AVVVAQID///8rVVVJtttgv98nTmJ2xNgkW1ttyNsmWWZmzNZYxM4gWGgeU2JmzNNr0N1Rwc0eU2VXxdEhV2JqytQeVmMhVmNoydUfVGUgVGQfVGQfVmVqy9hqy9dWw9AfVWRpydVry9YhVmMgVGNUw9BrytchVWRexdGw294gVWQgVmUhVWPd4N6HoaZsy9cfVmQgVGRrytZsy9cgVWQgVWMgVWRsy9YfVWNsy9YgVWVty9YgVWVry9UgVWRsy9Zsy9UfVWRsy9YgVWVty9YgVWRty9Vsy9aM09sgVWRTws/AzM0gVWRtzNYgVWRuy9Zsy9cgVWRGcHxty9bb5ORbxdEgVWRty9bn6OZTws9mydRfxtLX3Nva5eRix9NFcXxOd4JPeINQeIMiVmVUws9Vws9Vw9BXw9BYxNBaxNBbxNBcxdJexdElWWgmWmhjyNRlx9IqXGtoipNpytVqytVryNNrytZsjZUuX210k5t1y9R2zNR3y9V4lp57zth9zdaAnKOGoaeK0NiNpquV09mesrag1tuitbmj1tuj19uktrqr2d2svcCu2d2xwMO63N+7x8nA3uDC3uDFz9DK4eHL4eLN4eIyYnDX5OM5Z3Tb397e4uDf4uHf5uXi5ePi5+Xj5+Xk5+Xm5+Xm6OY6aHXQ19fT4+NfhI1Ww89gx9Nhx9Nsy9ZWw9Dpj2abAAAAWnRSTlMAAQICAwQEBgcIDQ0ODhQZGiAiIyYpKywvNTs+QklPUlNUWWJjaGt0dnd+hIWFh4mNjZCSm6CpsbW2t7nDzNDT1dje5efr7PHy9PT29/j4+Pn5+vr8/f39/f6DPtKwAAABTklEQVR4Xr3QVWPbMBSAUTVFZmZmhhSXMjNvkhwqMzMzMzPDeD+xASvObKePPa+ffHVl8PlsnE0+qPpBuQjVJjno6pZpSKXYl7/bZyFaQxhf98hHDKEppwdWIW1frFnrxSOWHFfWesSEWC6R/P4zOFrix3TzDFLlXRTR8c0fEEJ1/itpo7SVO9Jdr1DVxZ0USyjZsEY5vZfiiAC0UoTGOrm9PZLuRl8X+Dq1HQtoFbJZbv61i+Poblh/97TC7n0neCcK0ETNUrz1/xPHf+DNAW9Ac6t8O8WH3Vp98f5lCaYKAOFZMLyHL4Y0fe319idMNgMMp+zWVSybUed/+/h7I4wRAG1W6XDy4XmjR9HnzvDRZXUAYDFOhC1S/Hh+fIXxen+eO+AKqbs+wAo30zDTDvDxKoJN88sjUzDFAvBzEUGFsnADoIvAJzoh2BZ8sner+Ke/vwECuQAAAABJRU5ErkJggg==");
  background-position: 2px;
  background-repeat: no-repeat;
  border: none;
  width: 38px;
  height: 38px;
}

.layer-switcher button:focus, .layer-switcher button:hover {
  background-color: #fff;
}

.layer-switcher.shown {
  overflow-y: hidden;
}

.layer-switcher.shown.ol-control, .layer-switcher.shown.ol-control:hover {
  background-color: #0000;
}

.layer-switcher.shown .panel {
  display: block;
}

.layer-switcher.shown button {
  display: none;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  background-image: unset;
  background-color: #eee;
  margin: 0 1px;
  display: block;
  position: absolute;
  right: 2px;
}

.layer-switcher.shown button:focus, .layer-switcher.shown button:hover {
  background-color: #fafafa;
}

.layer-switcher ul {
  margin: 1.6em .4em;
  padding-left: 0;
  list-style: none;
}

.layer-switcher ul ul {
  margin: .1em 0 0;
  padding-left: 1.2em;
}

.layer-switcher li.group + li.group {
  margin-top: .4em;
}

.layer-switcher li.group > label {
  font-weight: bold;
}

.layer-switcher.layer-switcher-group-select-style-none li.group > label {
  padding-left: 1.2em;
}

.layer-switcher li {
  margin-top: .3em;
  position: relative;
}

.layer-switcher li input {
  width: 1em;
  height: 1em;
  font-size: 1em;
  position: absolute;
  left: 1.2em;
}

.layer-switcher li label {
  margin-top: 1px;
  padding-left: 2.7em;
  padding-right: 1.2em;
  display: inline-block;
}

.layer-switcher label.disabled {
  opacity: .4;
}

.layer-switcher input {
  margin: 0;
}

.layer-switcher.touch ::-webkit-scrollbar {
  width: 4px;
}

.layer-switcher.touch ::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #0000004d;
}

.layer-switcher.touch ::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #00000080;
}

li.layer-switcher-base-group > label {
  padding-left: 1.2em;
}

.layer-switcher .group button {
  vertical-align: top;
  float: none;
  -webkit-transition: -webkit-transform .2s ease-in-out;
  -ms-transition: -ms-transform .2s ease-in-out;
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAW0lEQVR4nGNgGAWMyBwXFxcGBgaGeii3EU0tXHzPnj1wQRYsihqQ+I0ExDEMQAYNONgoAN0AmMkNaDSyQSheY8JiaCMOGzE04zIAmyFYNTMw4A+DRhzsUUBtAADw4BCeIZkGdwAAAABJRU5ErkJggg==");
  background-position: 50% 2px;
  width: 1em;
  height: 1em;
  margin: 0;
  font-size: 1em;
  transition: transform .2s ease-in-out;
  display: inline-block;
  position: absolute;
  left: 0;
}

.layer-switcher .group.layer-switcher-close button {
  transform: rotate(-90deg);
}

.layer-switcher .group.layer-switcher-fold.layer-switcher-close > ul {
  height: 0;
  overflow: hidden;
}

.layer-switcher.shown.layer-switcher-activation-mode-click {
  padding-left: 34px;
}

.layer-switcher.shown.layer-switcher-activation-mode-click > button {
  border-right: 0;
  left: 0;
}

/*# sourceMappingURL=index.24fdf45e.css.map */
